<template>
  <div>
    <div class="row mb-1">
      <div class="col-xl-8 pl-0">
        <!-- <img src="media/images/adidas.svg" /> -->
        <!-- <img class="logo" :src="client_detail.logo" /> -->
        <div class="d-flex align-items-center">
          <div>
            <h1 class="ml-4 main-header">
              <b>Assign Coupons </b>
            </h1>
          </div>
          <div class="heading-status">
            <span :class="payload.status"
              ><b-icon icon="dot" scale="3"></b-icon
            ></span>
            {{ payload.status }}
          </div>
        </div>
        <h2 class="ml-4 client-name">
          for Coupon Series: {{ payload.series_name }}
        </h2>
      </div>
    </div>

    <b-row>
      <b-col id="edit-coupon">
        <step2
          class="box"
          :payload="payload"
          v-if="payload._id"
          type="edit"
        ></step2>
      </b-col>
    </b-row>

    <b-row class="mt-10">
      <b-col align-self="center" class="col-12 col-md-8 pl-0">
        <div>
          <ul class="type-list">
            <li
              :class="{ active: fType == 0 }"
              @click="activeType(0)"
              style="background-color: #00fdb1"
            >
              All Coupons
            </li>
            <li
              :class="{ active: fType == 1 }"
              @click="activeType(1)"
              style="background-color: #7600fd"
            >
              Unassigned Coupons
            </li>
            <li
              :class="{ active: fType == 2 }"
              @click="activeType(2)"
              style="background-color: #fbcd39"
            >
              Assigned Coupons
            </li>
          </ul>
        </div>
      </b-col>

      <!-- side menu filter -->
      <b-col class="col-12 col-md-4 side-menu">
        <b-input-group class="search-input bg-light">
          <b-input-group-prepend>
            <span class="input-group-text">
              <span class="svg-icon svg-icon-lg">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </b-input-group-prepend>
          <b-input
            class="form-control h-100"
            v-model="searchQuery"
            placeholder="search"
            type="text"
          />
        </b-input-group>

        <div
          id="kt_quick_fliter_toggle"
          style="margin-left: 10px; margin-right: 15px"
        >
          <a href="#">
            <inline-svg class="img" src="media/misc/adjustbutton.svg" />
          </a>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="row mb-20">
          <div class="col-xl-12">
            <div
              class="
                card card-custom card-stretch
                gutter-b
                card-shadowless
                my-class-shadow
              "
            >
              <div class="card-body">
                <div>
                  <b-table
                    class="ag-theme-material w-100"
                    sticky-header
                    hover
                    :fields="couponFields"
                    :items="filteredCoupons"
                    :filter="searchQuery"
                  >
                    <!-- A custom formatted header cell for select all checkbox -->
                    <template #head(index)="">
                      <b-form-checkbox
                        @change="selectAllCoupons"
                        class="custom-control custom-checkbox checkbox-xl"
                        :checked="selectedCoupons.length === couponList.length"
                      ></b-form-checkbox>
                    </template>

                    <!-- checkbox slot for each row -->
                    <template v-slot:cell(index)="slot">
                      <b-form-checkbox
                        name="selected-items"
                        class="custom-control custom-checkbox checkbox-xl"
                        v-model="selectedCoupons"
                        :value="slot.item"
                      >
                      </b-form-checkbox>
                    </template>
                    <!-- FOR ACTIONS  SLOT -->
                    <template v-slot:cell(action)="slot">
                      <b-row>
                        <b-col class="col-auto p-0 pr-3">
                          <b-button
                            class="action-btn"
                            @click.prevent="onClickViewRequest(slot.item)"
                            ><b-icon icon="eye-fill"
                          /></b-button>
                        </b-col>
                        <b-col class="col-auto p-0">
                          <b-button
                            class="action-btn"
                            @click="
                              selectedCoupon = slot.item;
                              $bvModal.show('delete-coupon');
                            "
                            v-b-modal.reject-request-modal
                            ><b-icon icon="x"
                          /></b-button>
                        </b-col>
                      </b-row>
                    </template>

                    <!-- FOR STATUS SLOT -->
                    <template v-slot:cell(status)="slot">
                      <b-button disabled style="width: 60%"
                        ><span :class="slot.item.status"
                          ><b-icon icon="dot" scale="3"></b-icon
                        ></span>
                        {{ slot.item.status }}</b-button
                      >
                    </template>
                  </b-table>
                  <b-row class="mt-15">
                    <b-col class="text-end">
                      <b-button
                        :disabled="!selectedCoupons || !selectedCoupons.length"
                        class="delete-btn"
                        @click="$bvModal.show('deleteAll-coupon')"
                        >DELETE
                      </b-button>
                    </b-col>
                    <!-- <b-col>
                      <b-button
                        class="add-btn"
                        :disabled="!selectedCoupons.length"
                        >ASSIGN
                        <b-spinner
                          v-if="isLoading"
                          small
                          type="grow"
                        ></b-spinner>
                      </b-button>
                    </b-col> -->
                  </b-row>
                </div>
                <div
                  class="no-records"
                  v-if="!couponList || !couponList.length"
                >
                  No Records to show
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="delete-coupon"
      :title="'Confirmation for Coupon ' + selectedCoupon.coupon"
      ok-variant="danger"
      ok-title="Remove"
      @ok="deleteCoupon"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete this coupon?</h5>
    </b-modal>
    <b-modal
      id="deleteAll-coupon"
      :title="'Confirmation for Coupons'"
      ok-variant="danger"
      ok-title="Remove"
      @ok="deleteCoupon"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete all following coupons?</h5>
      <ul style="margin-left: 20px">
        <li v-for="(item, i) in selectedCoupons" v-bind:key="i">
          {{ item.coupon }}
        </li>
      </ul>
    </b-modal>

    <b-modal id="assign-coupon-modal" centered hide-header hide-footer>
      <div class="assign-coupon">
        <b-row class="mb-5">
          <b-col>
            <h3><b>Assign Coupons</b></h3>
          </b-col>
          <b-col
            cols="auto"
            role="button"
            @click="$bvModal.hide('assign-coupon-modal')"
            class="close"
          >
            close
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-3">
            <span>COUPON SERIES</span>
            <br />
            <h4>{{ selectedCoupon.series_name }}</h4>
          </b-col>
          <b-col class="col-3">
            <span>COUPONS</span>
            <br />
            <h4>{{ selectedCoupon.coupon }}</h4>
            <!-- <span>+5 more</span> -->
          </b-col>
        </b-row>

        <form ref="form" class="form-data">
          <b-form-group
            id="select-channels"
            class="input-label"
            label="Channels"
            label-for="select-channels"
          >
            <b-input-group class="mt-3">
              <b-dropdown
                :text="
                  selectedCoupon.channel
                    ? selectedCoupon.channel
                    : selectedChannel
                "
              >
                <b-dropdown-item @click="selectedChannel = 'Influencer'"
                  >Influencer</b-dropdown-item
                >
              </b-dropdown>
            </b-input-group>
          </b-form-group>

          <!-- ADD BUTTON -->
          <b-form-group class="mt-15">
            <b-button
              class="add-btn"
              @click="
                assignCouponToChannel();
                $bvModal.hide('assign-coupon-modal');
              "
              >Confirm
              <b-spinner v-if="isLoading" small type="grow"></b-spinner>
            </b-button>
          </b-form-group>
        </form>
      </div>
    </b-modal>

    <b-modal id="re-assign-coupon-modal" centered hide-header hide-footer>
      <div class="assign-coupon">
        <b-row class="mb-5">
          <b-col>
            <span
              style="font-size: 18px; font-weight: bold; margin-right: 10px"
              >{{
                selectedCoupon.code
                  ? selectedCoupon.code
                  : selectedCoupon.coupon
              }}</span
            >
            <b-dropdown style="width:60%;" :text="selectedCoupon.status">
              <b-dropdown-item @click="onChangeStatus('active')"
                >active</b-dropdown-item
              >
              <b-dropdown-item @click="onChangeStatus('new')"
                >new</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col
            cols="auto"
            role="button"
            @click="$bvModal.hide('re-assign-coupon-modal')"
            class="close"
          >
            close
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-3">
            <span>COUPON SERIES</span>
            <br />
            <h4>
              <b>{{
                selectedCoupon.series_title
                  ? selectedCoupon.series_title
                  : selectedCoupon.series_name
              }}</b>
            </h4>
          </b-col>
        </b-row>

        <form ref="form" class="form-data">
          <b-form-group
            id="select-channels"
            class="input-label"
            label="Channels"
            label-for="select-channels"
          >
            <b-input-group class="mt-3">
              <b-dropdown :text="selectedCoupon.channel">
                <b-dropdown-item @click="selectedChannel = 'Influencer'"
                  >Influencer</b-dropdown-item
                >
              </b-dropdown>
            </b-input-group>
          </b-form-group>

          <b-row>
            <b-col>
              <h5><b>ASSIGNMENT HISTORY</b></h5>
            </b-col>
            <b-col cols="auto">
              <b-dropdown :text="sortBy">
                <b-dropdown-item @click="sortBy = 'Oldest First'"
                  >Oldest First</b-dropdown-item
                >
                <b-dropdown-item @click="sortBy = 'New First'"
                  >New First</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-list-group>
                <b-list-group-item
                  v-for="history in sortByType"
                  :key="history._id"
                  class="d-flex justify-content-between align-items-center"
                >
                  <b-row align-v="center">
                    <b-col cols="auto">
                      <b-img src="media/svg/misc/test.png" class="mr-2" />
                    </b-col>
                    <b-col cols="9">
                      {{ history.message }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>

          <!-- ADD BUTTON -->
          <b-form-group class="mt-15">
            <b-button
              class="add-btn"
              @click="$bvModal.hide('re-assign-coupon-modal')"
              >Confirm
              <b-spinner v-if="isLoading" small type="grow"></b-spinner>
            </b-button>
          </b-form-group>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import step1 from "./step1";
import step2 from "./step2";
import ApiService from "../../../core/services/api.service";
import { bus } from '../../../main'

export default {
  components: {
    step1,
    step2,
  },
  data() {
    return {
      e1: 1,
      steps: 2,
      payload: {},
      clientName: null,
      searchQuery: "",
      fType: 0,
      selectedCoupons: [],
      currentRowIndex: -1,
      isLoading: false,
      selectedChannel: "Select Channels",
      clientId: "",
      couponFieldsForAll: [
        {
          key: "index",
          label: "",
        },
        "coupon",
        "channel",
        "orders",
        "revenue",
        "date_added",
        "status",
        "action",
      ],
      couponFieldsUnassigned: [
        {
          key: "index",
          label: "",
        },
        "coupon",
        "orders",
        "revenue",
        "date_added",
        "status",
        "action",
      ],
      couponFields: [],
      couponList: [],
      filteredCoupons: [],
      couponChannels: [],
      selectedCoupon: {},
      sortBy: "Oldest First",
    };
  },
  mounted() {
    this.coupon_series_id = this.$route.params.id;
    this.getCouponChannels();
    if (this.coupon_series_id) {
      this.getCouponSeries();
      // this.getCoupon();
    }
    this.clientName = this.$route.query.name;
    this.couponFields = this.couponFieldsForAll;
    this.filteredCoupons = this.couponList;
  },
  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    payoutPayload(payload) {
      this.payload = payload;
      this.nextStep(1);
    },
    activeType(value) {
      this.fType = value;
      //all
      if (this.fType == 0) {
        this.couponFields = this.couponFieldsForAll;
        this.filteredCoupons = this.couponList;
      }
      // un assigned
      if (this.fType == 1) {
        this.couponFields = this.couponFieldsUnassigned;
        this.filteredCoupons = this.couponList.filter((x) => !x.channel);
      }
      // assigned
      if (this.fType == 2) {
        this.couponFields = this.couponFieldsUnassigned;
        this.filteredCoupons = this.couponList.filter((x) => x.channel);
      }
    },
    selectAllCoupons() {
      if (this.selectedCoupons.length === this.couponList.length) {
        this.selectedCoupons = [];
      } else {
        this.selectedCoupons = this.couponList.slice();
      }
    },
    handleSubmit() {
      console.log("assign");
    },
    onClickViewRequest(item) {
      this.selectedCoupon = item;
      if (item.channel) {
        this.$bvModal.show("re-assign-coupon-modal");
      } else {
        this.$bvModal.show("assign-coupon-modal");
      }
    },
    async onChangeStatus(status) {
      await ApiService.post("/coupons/update", {
        coupon_id: this.selectedCoupon._id,
        status: status,
      });
      this.selectedCoupon.status = status;
    },
    getCouponSeries() {
      ApiService.get(`/coupons/coupon-series/${this.coupon_series_id}`)
        .then((response) => {
          this.payload = response.data.data;
          this.clientName = this.payload.client_name;
          this.payload.coupon_series_id = this.coupon_series_id;
          this.payload.coupon_codes = response.data.data.coupon_codes.join("\n");
          this.clientId = response.data.data.client_id;
          this.getCouponsBySeriesId();
        })
        .catch((error) => {
          console.log("response response response", error);
        });
    },

    async getCouponDetail(id) {
      let couponDetail = await ApiService.get(`/coupons/${id}`);
      console.log("coupon detail", couponDetail);
    },

    getCouponsBySeriesId() {
      ApiService.post("coupons/coupon-series/coupons", {
        coupon_series_id: this.coupon_series_id,
      })
        .then((response) => {
          this.couponList = response.data.data.map((x) => {
            return {
              _id: x._id,
              coupon: x.code,
              channel: x.channel,
              orders: x.order_num,
              revenue: `${x.total_revenue_currency} ${x.revenue}`,
              date_added: new Date(x.active_from).toLocaleDateString(
                undefined,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              ),
              status: x.status,
              action: "",
              series_name: x.series_title,
              assignment_history: x.assignment_history,
            };
          });
          this.filteredCoupons = this.couponList;

          console.log("response2 response2 response2", response);
        })
        .catch((error) => {
          console.log("response1 response1 response1", error);
        });
    },
    async getCouponChannels() {
      this.couponChannels = await ApiService.get(
        "/coupons/coupon-series/coupon-channel-list"
      );
      console.log("channels", this.couponChannels);
    },
    async assignCouponToChannel() {
      await ApiService.post("coupons/coupon-series/assign-channel", {
        coupon_series_id: this.coupon_series_id,
        coupons: this.selectedCoupon._id
          ? [this.selectedCoupon._id]
          : this.selectedCoupons.map((x) => x._id),
        channel: this.selectedChannel,
      });
      this.getCouponsBySeriesId();
    },
    async deleteCoupon() {
      bus.$emit('selectedCoupon', this.selectedCoupon);
      await ApiService.post("/coupons/delete", {
        coupon_ids: this.selectedCoupon._id
          ? [this.selectedCoupon._id]
          : this.selectedCoupons.map((x) => x._id),
      });
      this.selectedCoupons = [];
      this.selectedCoupon = {};
      this.getCouponsBySeriesId();
    },
  },
  computed: {
    sortByType() {
      if (!this.selectedCoupon || !this.selectedCoupon.assignment_history) {
        return [];
      }
      let arr = this.selectedCoupon.assignment_history;
      if (this.sortBy == "New First") {
        return arr.sort(function(a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      } else {
        return arr.sort(function(a, b) {
          return new Date(a.created_at) - new Date(b.created_at);
        });
      }
    },
  },

  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#edit-coupon {
  .box {
    border-radius: 20px !important;
    box-shadow: 0px 14px 44px #eee !important;
  }
}
.search-input {
  height: 46px !important;
}
.heading-status{
  font-size: 14px;
  font-weight: 500;
  color: #707070;
  text-transform: uppercase;
  padding: 8px 15px;
  margin-left: 2rem;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 0px 20px #eee;

}
.img {
  width: 46px !important;
  height: 46px !important;
}
.logo {
  height: 70px;
}
.client-name {
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #939393;
}
input {
  // height: 45px;
  background: #f3fcff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font-size: 18px;
  color: #000000;
}
::v-deep label {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #000 !important;
}
.add-btn,
.delete-btn {
  font-size: 16px;
  font-weight: bold;
  width: unset;
  height: unset;
  background: #1374d6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  letter-spacing: unset;
  color: #ffffff;
  float: unset;
  transition: all 0.3s ease;
}
.delete-btn {
  background: rgb(224, 143, 143);
  color: rgb(141, 20, 20);
  padding: 8px 20px;
}
.input-info {
  color: #000000;
  font-size: 14px;
  line-height: 30px;
}
.active-step,
.v-stepper__step__step primary {
  background: #00a4f3 0% 0% no-repeat padding-box !important;
}
.box {
  border-radius: 4px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  // -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.side-menu {
  display: flex;
  justify-content: space-between;
}
.type-list {
  list-style: none;
  display: flex;

  label {
    display: inline-block;
    margin-bottom: 0rem !important;
    font-size: 16px;
    font-weight: 500 !important;
  }

  li {
    margin-right: 1rem;
    border: 1px solid #a4c5e2;
    padding: 10px;
    color: black !important;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    width: 200px;
    text-align: center;
    background-color: #e7eff7 !important;
  }

  .active {
    color: white !important;
    font-weight: bold !important;
    background-color: #1374d6 !important;
  }
}
::v-deep .table {
  height: calc(100% - 25px);
  th,
  td {
    vertical-align: middle;
    padding: 20px;
  }
  thead {
    color: #000;
    th {
      text-transform: uppercase;
      padding-bottom: 15px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::after {
    box-shadow: 0 0 0 2px rgba(32, 33, 36, 0.122) !important;
    border: none !important;
  }

  .checkbox-xl .custom-control-label::before,
  .checkbox-xl .custom-control-label::after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    opacity: 1;
    font-weight: 400;
    border: 2px solid #0000008a;
  }
  .b-avatar {
    height: 25px;
    width: 25px;
  }
  .cancelled {
    color: #ff0000;
  }
  .completed {
    color: #01e994;
  }
  .pending,
  .pre-approved {
    color: #e9a6a6;
  }
  .action-btn {
    width: 30px;
    height: 30px;
    padding: 0px 0px 3px 0px;
    background: #ececec;
    border-radius: 27px;
    svg {
      color: #8e3ceb;
    }
  }
}

.logo {
  height: 70px;
}
.card.card-custom.card-stretch.gutter-b {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.card.card-custom > .card-body {
  padding: 1rem 2em;
}
.card {
  border-radius: 20px;
}

/* Scroll bar style */
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff0d9;
    outline: 1px solid #fff0d9;
  }
}
.no-records {
  height: 400px;
  text-align: center;
  padding: 50px;
}
</style>
